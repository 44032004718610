import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { changeLanguageApp, changeSlugApp } from '../../../store/actions';
import { CommonUtils, LANGUAGES, TEXT_WIDGET, path } from '../../../utils';
import laravelService from '../../../services/laravelService';

class Topbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textWidget: null,
        };
    };

    async getTextWidget() {
        let res = await laravelService.getTextWidget(TEXT_WIDGET.TEXT_HEADER);
        if (res && res.data) {
            this.setState({
                textWidget: res.data,
            });
        }
    };
    async componentDidMount() {
        this.getTextWidget();

    }
    changeLanguage = (language) => {
        // fire action redux event
        this.props.changeLanguageAppRedux(language);
    }

    handleViewHome = () => {

        this.props.changeSlugAppRedux("");
    }
    render() {
        let language = this.props.language;
        const { textWidget } = this.state;
        document.title = textWidget ? CommonUtils.getTitleBylanguage(textWidget, language)
            : "YoSoft";
        document.getElementsByTagName("META")[4].content = textWidget ? CommonUtils.getContentBylanguage(textWidget, language)
            : "Home page";
        return (
            <nav className="top-bar-container w-full py-1 shadow">
                <div className="top-bar w-full container mx-auto flex flex-wrap items-center justify-between">

                    <nav>
                        <ul className="flex items-center justify-between font-bold text-sm text-white uppercase no-underline">
                            <li>
                                <a href={path.HOME} className="hover:text-gray-200 hover:underline px-4" onClick={() => this.handleViewHome()}>

                                    <img src="/yosoft_logo.png" alt="Logo" className="h-32 w-32 mr-2" />
                                </a>
                            </li>
                            {/* <li><a className="hover:text-gray-200 hover:underline px-4" href=""><FormattedMessage id="Topbar.company-overview" /></a></li> */}
                        </ul>
                    </nav>

                    <div className="flex flex-col items-center py-6">
                        <a href={path.HOME} className="font-bold uppercase hover:text-gray-700 text-5xl" onClick={() => this.handleViewHome()}>
                            {textWidget ?
                                CommonUtils.getTitleBylanguage(textWidget, language) : ""}
                        </a>
                        {/* <p className="text-lg text-gray-600"> */}
                        <div className="pb-2 text-lg text-gray-600" dangerouslySetInnerHTML={{ __html: textWidget ? CommonUtils.getContentBylanguage(textWidget, language) : "" }} />
                        {/* </p> */}
                    </div>

                    <div className="flex items-center text-lg no-underline pr-6">
                        <Link to={path.CONTACT} className='support'><i className="fas fa-question-circle"></i></Link>
                        <a className={language === LANGUAGES.JA ? 'pl-3 language-jp active' : 'pl-3 language-jp'}><span onClick={() => this.changeLanguage(LANGUAGES.JA)}>JP</span></a>
                        <a className={language === LANGUAGES.VI ? 'pl-3 language-vi active' : 'pl-3 language-vi'}><span onClick={() => this.changeLanguage(LANGUAGES.VI)}>VN</span></a>
                        <a className={language === LANGUAGES.EN ? 'pl-3 language-en active' : 'pl-3 language-en'}><span onClick={() => this.changeLanguage(LANGUAGES.EN)}>EN</span></a>
                        {/* <a className="" href="#">
                            <i className="fab fa-facebook"></i>
                        </a>
                        <a className="pl-6" href="#">
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a className="pl-6" href="#">
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a className="pl-6" href="#">
                            <i className="fab fa-linkedin"></i>
                        </a> */}
                    </div>
                </div>
            </nav>

        );
    }

}

const mapStateToProps = state => {
    return {
        // isLoggedIn: state.user.isLoggedIn,
        language: state.app.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeLanguageAppRedux: (language) => dispatch(changeLanguageApp(language)),
        changeSlugAppRedux: (slug) => dispatch(changeSlugApp(slug))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);


