import React, { Component } from 'react';
import { connect } from 'react-redux';
import laravelService from '../../../services/laravelService';
import { CommonUtils, LanguageUtils, TEXT_WIDGET, path } from '../../../utils';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
// import MetaTags from 'react-meta-tags';


class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textWidget: null,
            textHeader: null,
        };
    };

    async getTextWidget() {
        let res = await laravelService.getTextWidget(TEXT_WIDGET.Footer_US_SIDEBAR);
        if (res && res.data) {
            this.setState({
                textWidget: res.data,
            });
        }
    };
    async getTextHeader() {
        let res = await laravelService.getTextWidget(TEXT_WIDGET.TEXT_HEADER);
        if (res && res.data) {
            this.setState({
                textHeader: res.data,
            });
        }
    };

    async componentDidMount() {
        // this.getTextWidget();
        // this.getTextHeader();

    }

    render() {
        const { textWidget, textHeader } = this.state;
        const { language } = this.props;

        document.title = textHeader ? CommonUtils.getTitleBylanguage(textHeader, language)
            : "YoSoft - Footer us";
        document.getElementsByTagName("META")[4].content = textHeader ? CommonUtils.getContentBylanguage(textHeader, language)
            : "Footer us";
        return (

            <footer className="w-full border-t bg-white pb-12">

                <div className="w-full container mx-auto flex flex-col items-center">
                    <div className="flex flex-col md:flex-row text-center md:text-left md:justify-between py-6">
                        <Link to={path.ABOUT} className="uppercase px-3"><FormattedMessage id="Topic.about-us" /></Link>
                        <Link to={path.POLICY} className="uppercase px-3"><FormattedMessage id="Topic.privacy-policy" /></Link>
                        {/* <a href="#" className="uppercase px-3">Terms & Conditions</a> */}
                        <Link to={path.CONTACT} className="uppercase px-3"><FormattedMessage id="Topic.contact-us" /></Link>
                    </div>
                    <div className="uppercase pb-6">&copy; yosoft.jp</div>
                </div>
            </footer>

        );
    }

}

const mapStateToProps = state => {
    return {
        language: state.app.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);


