import React, { Component } from 'react';
import { connect } from 'react-redux';
import laravelService from '../services/laravelService';
import { CommonUtils, TEXT_WIDGET } from '../utils';
// import MetaTags from 'react-meta-tags';


class Information extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textWidget: null,
            textHeader: null,
        };
    };

    async getTextWidget() {
        let res = await laravelService.getTextWidget(this.props.textWidget);
        if (res && res.data) {
            this.setState({
                textWidget: res.data,
            });
        }
    };
    async getTextHeader() {
        let res = await laravelService.getTextWidget(TEXT_WIDGET.TEXT_HEADER);
        if (res && res.data) {
            this.setState({
                textHeader: res.data,
            });
        }
    };

    async componentDidMount() {
        this.getTextWidget();
        this.getTextHeader();

    }
    
    async componentDidUpdate(prevProps, prevState, snapshot) {
        // if (prevProps.language !== this.props.language && this.state.category) {
        //     CommonUtils.setMetaCateBylanguage(this.state.category, this.props.language);
        // }
        console.log("information componentDidUpdate : ", this.props)
        if (prevProps.textWidget !== this.props.textWidget) {
            this.getTextWidget();
        }
    }

    render() {
        const { textWidget, textHeader } = this.state;
        const { language } = this.props;

        document.title = textHeader ? CommonUtils.getTitleBylanguage(textHeader, language)
            : "";
        document.getElementsByTagName("META")[4].content = textHeader ? CommonUtils.getContentBylanguage(textHeader, language)
            : "";
        return (

            <article className="w-full flex flex-col shadow my-4">
                <a href="" className="hover:opacity-75">
                    <img className='w-full' src={textWidget ? CommonUtils.getUrlImage(textWidget.image, CommonUtils.getStoragePath()) : ""} />
                </a>
                <div className="bg-white flex flex-col justify-start p-6">
                    <h1 className="text-3xl font-bold hover:text-gray-700 pb-4">{textWidget ? CommonUtils.getTitleBylanguage(textWidget, language) : ""}</h1>
                    <div className="pb-2" dangerouslySetInnerHTML={{ __html: textWidget ? CommonUtils.getContentBylanguage(textWidget, language) : "" }} />

                </div>
            </article>

        );
    }

}

const mapStateToProps = state => {
    return {
        language: state.app.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Information);


