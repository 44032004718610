import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';


// import './ConfirmModal.scss';
import * as actions from "../store/actions";
import { CommonUtils, KeyCodeUtils, path } from "../utils";
import postService from '../services/laravelService';

class PostItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount() {


    }

    async componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleClickPost = () => {
        this.props.handleClickPost(this.props.post);
    };


    render() {
        const { post, storagePath, language } = this.props;
        const categories = post.categories;
        const user = post.user;
        // console.log('render postitem: ', categories);
        return (
            <article className="flex flex-col shadow my-4" id={post.title_JP}>
                <a className="hover:opacity-75" onClick={() => this.handleClickPost()} >
                    <img src={CommonUtils.getUrlImage(post.thumbnail, storagePath)} />
                </a>
                <div className="bg-white flex flex-col justify-start p-6">
                    <div className="flex gap-4">
                        {categories && categories.length > 0 &&
                            categories.map((item, index) => {
                                return <a href="#" className="text-blue-700 text-sm font-bold uppercase pb-4" key={index}>{CommonUtils.getTitleBylanguage(item, language)}</a>
                            })
                        }
                    </div>
                    <a className="text-3xl font-bold hover:text-gray-700 pb-4" onClick={() => this.handleClickPost()} >{CommonUtils.getTitleBylanguage(post, language)}</a>
                    {/* <p href="#" className="text-sm pb-3">
                        By <a href="#" className="font-semibold hover:text-gray-800">{user && user.name ? user.name : ""}</a>, Published on {post.getFormattedDateJp}
                    </p> */}
                    {/* <a className="pb-6" onClick={() => this.handleClickPost()} >{CommonUtils.getShortBodyBylanguage(post, language)}</a> */}
                    {/* <a className="uppercase text-gray-800 hover:text-black" onClick={() => this.handleClickPost()} ><FormattedMessage id="PostItem.continue-reading" /> <i className="fas fa-arrow-right"></i></a> */}
                    <div className="pb-2" dangerouslySetInnerHTML={{ __html: post ? CommonUtils.getBodyBylanguage(post, language) : "" }} />

                </div>
            </article>
        );
    }

}

const mapStateToProps = state => {
    return {
        language: state.app.language,
        // contentOfConfirmModal: state.app.contentOfConfirmModal
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostItem);
