import axios from '../axios';
const laravelService = {

    getAllPosts(page) {
        let path = `/api/v1/posts`;
        if (page && page >= 1) {
            path += `?page=${page}`;
        }
        return axios.get(path);
    },
    getTextWidget(key) {
        console.log('getTextWidget:', key);
        return axios.get(`/api/v1/textWidget/${key}`);
    },
    getCategories() {
        return axios.get(`/api/v1/categories`);
    },
    getCategoriesTopics() {
        return axios.get(`/api/v1/cate-Topics`);
    },
    getPostsByCategory(slug) {
        return axios.get(`/api/v1/category/${slug}`);
    },
    getImageSides() {
        return axios.get(`/api/v1/image-side`);
    },
    createContact(data) {
        return axios.post(`/api/v1/contact`, data);
    }

}

export default laravelService;